<template>
  <div
    class="etug-standards px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent class="py-8" title="ETUG Standards" size="large" />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <p
              style="color:rgb(127, 137, 145);line-height:32px;font-size:15px;"
            >
              This page contains links to the ET Users Group standards that can
              be downloaded. This is the repository where the most current
              version is located. If you have changes or suggestions please use
              the
              <router-link to="/contact-us" class="link"
                >Contact Us</router-link
              >
              page or give us a call at 801-567-0456 and we can address them
              together at the next meeting.
            </p>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <h2
                class="mt-4 mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                STANDARDS
              </h2>
              <div
                style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
                class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
                role="tabpanel"
              >
                <div class="panel-heading pl-6">
                  <h4
                    class="panel-title toggle"
                    style="position:relative;"
                    data-fontsize="16"
                    data-lineheight="22"
                  >
                    <a
                      @click="showContent = showContent === 1 ? false : 1"
                      :class="{ open: showContent === 1 }"
                      style="cursor:pointer;"
                      aria-expanded="false"
                      aria-selected="false"
                      aria-controls="811950d795ab93cb4"
                      role="tab"
                      data-toggle="collapse"
                    >
                      <span
                        class="fusion-toggle-icon-wrapper"
                        aria-hidden="true"
                      >
                        <i class="fa-fusion-box"></i>
                      </span>
                      <span
                        class="fusion-toggle-heading font-bold"
                        style="color:#ef9a3d;"
                        >ETUG Standard for In-Process Hazard
                        Classification</span
                      >
                    </a>
                  </h4>
                </div>
                <transition name="fade">
                  <div
                    id="811950d795ab93cb4"
                    class="panel-collapse collapse"
                    v-if="showContent === 1"
                  >
                    <div class="panel-body toggle-content fusion-clearfix pt-6">
                      <a
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/ETUG-Standard-ETUG-GS01-15.pdf?alt=media&token=8f0c42a1-7359-4473-82c4-7997887eca5e"
                        >ETUG-GS01-15: ETUG Standard for In-Process Hazard
                        Classification of Explosives</a
                      >
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;padding-bottom:180px;"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.etug-standards {
  ul {
    list-style: none;
    li {
      position: relative;
    }
  }

  ul li:before {
    top: 5px;
    position: absolute;
    content: url("../../assets/angle-right-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>
